import React, { useState } from "react";
import { Badge, Button, Card, List, Select } from "flowbite-react";
import { useQuery } from "react-query";
import { DASHBOARDS } from "../config/apiConfig";
import axios from "../config/axiosInstance";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import DrawerLoading from "./DrawerLoading";
import { formatCurrencyBRL } from "../utils/utils";
import { FaCrown } from "react-icons/fa";

const Dashboard = () => {
  const [selectTopFreeCustomers, setSelectTopFreeCustomers] = useState(
    moment().format("yyyy-MM-DD")
  );
  const [filterPendingAppointments, setFilterPendingAppointments] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );

  const {
    isLoading: isLoadingDashboardInfos,
    error: errorDashboardInfos,
    data: dashboardInfos,
  } = useQuery(
    ["dashboardInfos", filterPendingAppointments, selectTopFreeCustomers],
    async () => {
      const { data } = await axios.get(
        `${DASHBOARDS.GET_INFOS}?date=${selectTopFreeCustomers}`
      );

      return data;
    }
  );

  const chartColors = [
    "var(--secondary)",
    "#00E396",
    "#FEB019",
    "#FF4560",
    "#775DD0",
  ];

  return (
    <>
      <div className="rounded-lg dark:border-gray-700 mt-3">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-4 mb-4">
          <Card className="card-dashboard">
            <div className="flex justify-between items-center gap-2">
              <h2 className="text-lg text-center font-bold">
                Top Clientes Free / Agendamentos
              </h2>
              <Select
                value={selectTopFreeCustomers}
                onChange={(e) => setSelectTopFreeCustomers(e.target.value)}
              >
                <option value={moment().format("yyyy-MM-DD")}>Hoje</option>
                <option
                  value={moment().subtract(7, "days").format("yyyy-MM-DD")}
                >
                  7 dias
                </option>
                <option
                  value={moment().subtract(30, "days").format("yyyy-MM-DD")}
                >
                  30 dias
                </option>
                <option value={""}>Desde o início</option>
              </Select>
            </div>
            {dashboardInfos?.topCustomersFree?.length > 0 ? (
              <List
                unstyled
                className="w-full divide-y divide-gray-200 dark:divide-gray-700"
              >
                {dashboardInfos?.topCustomersFree?.map((company, index) => (
                  <List.Item className="mb-3 pt-3 pb-2" key={index}>
                    <div className="flex justify-between items-center">
                      <div className="flex flex-col items-start">
                        <div>{company?.Company.name}</div>
                      </div>

                      <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                        {index === 0 && (
                          <FaCrown className="mr-2" color="var(--primary)" />
                        )}
                        Qtd. {company?.appointmentCount}
                      </div>
                    </div>
                  </List.Item>
                ))}
              </List>
            ) : (
              <div className="text-center h-60 flex items-center justify-center font-semibold">Nenhum agendamento hoje</div>
            )}
          </Card>
          <Card className="card-dashboard">
            <h2 className="text-lg text-center font-bold">
              Clientes por plano contratado
            </h2>
            <ReactApexChart
              options={{
                chart: {
                  height: 350,
                  type: "bar",
                  events: {
                    click: function (chart, w, e) {
                      // console.log(chart, w, e)
                    },
                  },
                },
                plotOptions: {
                  bar: {
                    columnWidth: "45%",
                    distributed: true,
                  },
                },
                dataLabels: {
                  enabled: true,
                },
                legend: {
                  show: true,
                },
                xaxis: {
                  categories: ["FREE", "PRO"],
                  labels: {
                    style: {
                      fontSize: "13px",
                    },
                  },
                },
              }}
              series={[
                {
                  name: "Qtd. de clientes",
                  data: dashboardInfos?.companiesCountByPlan,
                },
              ]}
              type="bar"
              height={350}
            />
            <div className="font-semibold">
              Total estimado por mês:{" "}
              {formatCurrencyBRL.format(
                dashboardInfos?.companiesCountByPlan[1] * 29.9
              )}
            </div>
            <small className="-mt-4 italic">
              Obs: alguns clientes são parcerias
            </small>
          </Card>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 mb-4">
          <Card className="card-dashboard bg-gray-700 relative">
            <h2 className="text-lg text-center font-bold text-white">
              Total de agendamentos
            </h2>
            <div className="flex justify-center gap-6">
              <div>
                <div className="text-white font-semibold mb-1">Pendentes</div>
                <Badge
                  className="mx-auto font-semibold w-fit"
                  color="gray"
                  size="lg"
                >
                  {dashboardInfos?.countTotalAppointments[0]?.count}
                </Badge>
              </div>
              <div>
                <div className="text-white font-semibold mb-1">Confirmados</div>
                <Badge
                  className="mx-auto font-semibold w-fit"
                  color="success"
                  size="lg"
                >
                  {dashboardInfos?.countTotalAppointments[1]?.count}
                </Badge>
              </div>
            </div>
            {isLoadingDashboardInfos && <DrawerLoading />}
          </Card>
          <Card className="card-dashboard bg-gray-700 relative">
            <h2 className="text-lg text-center font-bold text-white">
              Total de comandas
            </h2>
            <div className="flex justify-center gap-6">
              <div>
                <div className="text-white font-semibold mb-1">Pendentes</div>
                <Badge
                  className="mx-auto font-semibold w-fit"
                  color="gray"
                  size="lg"
                >
                  {dashboardInfos?.countTotalCommands[0]?.count}
                </Badge>
              </div>
              <div>
                <div className="text-white font-semibold mb-1">Faturadas</div>
                <Badge
                  className="mx-auto font-semibold w-fit"
                  color="success"
                  size="lg"
                >
                  {dashboardInfos?.countTotalCommands[1]?.count}
                </Badge>
              </div>
            </div>
            {isLoadingDashboardInfos && <DrawerLoading />}
          </Card>
          <Card className="card-dashboard bg-gray-700 relative">
            <h2 className="text-lg text-center font-bold text-white">
              Total de usuários
            </h2>
            <div className="flex justify-center gap-6">
              <div>
                <div className="text-white font-semibold mb-1">Ativos</div>
                <Badge
                  className="mx-auto font-semibold w-fit"
                  color="success"
                  size="lg"
                >
                  {dashboardInfos?.countTotalUsers[1]?.count}
                </Badge>
              </div>
              <div>
                <div className="text-white font-semibold mb-1">Inativos</div>
                <Badge
                  className="mx-auto font-semibold w-fit"
                  color="pink"
                  size="lg"
                >
                  {dashboardInfos?.countTotalUsers[0]?.count}
                </Badge>
              </div>
            </div>
            {isLoadingDashboardInfos && <DrawerLoading />}
          </Card>
          <Card className="card-dashboard bg-gray-700 relative">
            <h2 className="text-lg text-center font-bold text-white">
              Total de serviços
            </h2>
            <div className="flex justify-center gap-6">
              <div>
                <div className="text-white font-semibold mb-1">Ativos</div>
                <Badge
                  className="mx-auto font-semibold w-fit"
                  color="success"
                  size="lg"
                >
                  {dashboardInfos?.countTotalServices}
                </Badge>
              </div>
            </div>
            {isLoadingDashboardInfos && <DrawerLoading />}
          </Card>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mb-4">
          <Card className="card-dashboard relative">
            <div className="flex items-center justify-between gap-2">
              <h2 className="text-base font-bold">
                Top clientes PRO / agendamentos
              </h2>
              <Select
                value={selectTopFreeCustomers}
                onChange={(e) => setSelectTopFreeCustomers(e.target.value)}
              >
                <option value={moment().format("yyyy-MM-DD")}>Hoje</option>
                <option
                  value={moment().subtract(7, "days").format("yyyy-MM-DD")}
                >
                  7 dias
                </option>
                <option
                  value={moment().subtract(30, "days").format("yyyy-MM-DD")}
                >
                  30 dias
                </option>
                <option value={""}>Desde o início</option>
              </Select>
            </div>
            <List
              unstyled
              className="max-w-md divide-y divide-gray-200 dark:divide-gray-700"
            >
              {dashboardInfos?.topCustomers?.map((company, index) => (
                <List.Item className="mb-3 pt-3 pb-2" key={index}>
                  <div className="flex justify-between items-center">
                    <div className="flex flex-col items-start">
                      <div>{company?.Company.name}</div>
                    </div>

                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                      {index === 0 && (
                        <FaCrown className="mr-2" color="var(--primary)" />
                      )}
                      Qtd. {company?.appointmentCount}
                    </div>
                  </div>
                </List.Item>
              ))}
            </List>
            {isLoadingDashboardInfos && <DrawerLoading />}
          </Card>

          <Card
            className="card-dashboard relative"
            style={{ minHeight: "270px" }}
          >
            <h2 className="text-center font-bold text-base">
              {`Últimos clientes cadastrados `}
            </h2>
            <List
              unstyled
              className="max-w-md divide-y divide-gray-200 dark:divide-gray-700"
            >
              {dashboardInfos?.latestCustomers?.map((customer, index) => (
                <List.Item className="mb-3 pt-3 pb-2" key={index}>
                  <div className="flex justify-between items-center">
                    <div className="flex flex-col items-start">
                      <div>{customer?.name}</div>
                    </div>

                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                      {moment(customer?.createdAt).format("DD/MM/yyyy")}
                    </div>
                  </div>
                </List.Item>
              ))}
            </List>
            {isLoadingDashboardInfos && <DrawerLoading />}
          </Card>
          <Card className="text-center card-dashboard relative">
            <div className="text-base font-bold flex items-center justify-center gap-2">
              <span>
                Próximos de expirar (
                {dashboardInfos?.companiesCloseToExpire?.companiesToExpire
                  ?.length || 0}
                )
              </span>
            </div>
            <div
              className="flex flex-col gap-3"
              style={{
                overflowY: "hidden",
                height: "400px",
              }}
            >
              <div
                className="flex flex-col gap-3"
                style={{
                  overflowY: "scroll",
                }}
              >
                <List
                  unstyled
                  className="max-w-md divide-y divide-gray-200 dark:divide-gray-700"
                >
                  {dashboardInfos?.companiesCloseToExpire?.companiesToExpire?.map(
                    (company, index) => (
                      <List.Item className="mb-2 pt-2" key={index}>
                        <div className="flex justify-between items-center">
                          <div className="flex flex-col items-start">
                            <div>{company.name}</div>
                            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              {company.phoneNumber}
                            </div>
                          </div>

                          <div className="flex flex-col gap-1 items-end text-base font-semibold text-gray-900 dark:text-white">
                            <div>
                              {moment(company.planExpirationDate).format(
                                "DD/MM/yyyy"
                              )}
                            </div>
                          </div>
                        </div>
                      </List.Item>
                    )
                  )}
                </List>
              </div>
            </div>
            {isLoadingDashboardInfos && <DrawerLoading />}
          </Card>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
